import React from "react";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import { ReactComponent as RykiLogo } from "assets/ryki_dark_logo.svg";

const KYFooter = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#081E30",
        width: "100%",
        position: "relative",
        bottom: 0,
        p: 2,
      }}
      component="footer"
    >
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
          }}
        >
          {/* <RykiLogo /> */}
          <Typography variant="h6" color="white">
            RKFX
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} container style={{ textAlign: "left" }}>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              marginTop: 24,
              marginBottom: 8,
            }}
          >
            <Grid item xs={12}>
              <Typography variant="body1" color="white" component="p">
                RKFX
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Link
                variant="body1"
                sx={{ color: "#1C98D9" }}
                href="tel:+12262407189"
              >
                (226) 240-7189
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" color="white" component="p">
                DDM Corporate Ltd, 3rd Floor One Capital Place,
              </Typography>
              <Typography variant="body1" color="white" component="p">
                PO Box 10190 Grand Cayman, Cayman Islands
              </Typography>
            </Grid>
            {/* <Grid item xs={12}>
              <Typography variant="body1" color="white" component="p">
                CIMA VASP no. 1917020
              </Typography>
            </Grid> */}
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            container
            style={{
              textAlign: "left",
              marginTop: 24,
              marginBottom: 8,
            }}
          >
            <Grid item xs={12}>
              <Link
                variant="body1"
                sx={{ color: "#1C98D9", paddingBottom: 8 }}
                href="/terms-conditions"
              >
                <Typography variant="body1" color="white" component="p">
                  Terms and Conditions
                </Typography>
              </Link>
              
              <Link
                variant="body1"
                sx={{ color: "#1C98D9", paddingBottom: 8 }}
                href="/contact-us"
              >
                <Typography variant="body1" color="white" component="p">
                  Contact Us
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default KYFooter;
