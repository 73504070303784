import React from "react";
import { Grid, Container, Box, Typography } from "@mui/material";

const ContactUSPage = () => {
  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 8,
          pb: 6,
          width: "100%",
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={6} md={8}>
            <Typography
              variant="h2"
              align="left"
              color="text.primary"
              gutterBottom
            >
              Are you ready to experience a better way to trade FX?
            </Typography>
            <Typography variant="body2" align="left" color="text.primary">
              Fill out our form and a representative will be in touch.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            style={{
              height: "100vh",
            }}
          >
            <iframe
              height="100%"
              title="Embedded Wufoo Form"
              frameBorder="0"
              scrolling="no"
              sandbox="allow-popups-to-escape-sandbox allow-top-navigation allow-scripts allow-popups allow-forms allow-same-origin"
              src="https://ryki.wufoo.com/embed/z1wrwcj30yn19go/"
            >
              <a href="https://ryki.wufoo.com/forms/z1wrwcj30yn19go/">
                Fill out my Contact form!
              </a>
            </iframe>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ContactUSPage;
