import React from "react";
import Drawer from "@mui/material/Drawer";
import {
  Box,
  Typography,
  Toolbar,
  AppBar,
  IconButton,
  List
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import MenuIcon from "@mui/icons-material/Menu";

const drawerWidth = 240;

const NavigationBar = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const navigate = useNavigate();
  
  const navItems = [
    { label: "Contact Us", path: "/contact-us" },
    { label: "Terms & Conditions", path: "/terms-conditions" },
  ];

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: "center",
      }}
    >
      <List>
        {navItems.map((item) => (
          <ListItem key={item.label} disablePadding>
            <ListItemButton
              sx={{
                textAlign: "left",
                backgroundColor: "white",
              }}
              onClick={() =>navigate(item.path)
              }
            >
              <ListItemText
                primaryTypographyProps={{
                  variant: "button",
                  sx: {
                    fontWeight: "bolder",
                  },
                }}
                primary={item.label}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" elevation={0} >
        <Toolbar sx={{minHeight:64}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            {mobileOpen ? <CancelOutlinedIcon /> : <MenuIcon />}
          </IconButton>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: { xs: "center", sm: "left" },
              justifyContent: { xs: "center", sm: "left" },
            }}
          >
            <Box
              sx={{
                alignItems: { xs: "center", sm: "left" },
                justifyContent: { xs: "center", sm: "left" },
                paddingRight: { xs: 4, sm: "none" },
                display: "flex",
              }}
            >
              {/* <RykiLogo
                width={64}
                height={64}
                onClick={() => navigate("/ca")}
              /> */}
              <Typography variant="h6" color="inherit" noWrap onClick={() => navigate("/")}>
                RKFX
              </Typography>
            </Box>
          </Box>

        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
            BackdropProps: {
              sx: {
                top: 64,
              },
            },
          }}
          sx={{
            position: "absolute",
            top: 64,
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              position: "absolute",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};
export default NavigationBar;
