import React from "react";
import "./App.css";
import AppRoutes from "./components/Routes/AppRoutes";
import { ThemeProvider } from "@emotion/react";
import theme from "components/Theme/theme";
import CssBaseline from "@mui/material/CssBaseline";
function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppRoutes />
    </ThemeProvider>
  );
}

export default App;
