import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

interface PartnerCardProps {
  logo: string;
  altText: string;
}

const PartnerCard = (props: PartnerCardProps) => {
  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardContent
        sx={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={props.logo}
          alt={props.altText}
          style={{
            minWidth: "100%",
            minHeight: "100%",
            maxHeight: 88,
            objectFit: "contain",
            padding: 8,
          }}
        />
      </CardContent>
    </Card>
  );
};

export default PartnerCard;
