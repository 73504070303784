import React from "react";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import PartnerCard from "components/PartnerCard/PartnerCard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ProvenanceLogo from "assets/ky/provenance_logo.jpeg";
import DentonsLogo from "assets/ky/dentons-logo.jpeg";
import RSMLogo from "assets/ky/rsm-logo.png";
// import CIMALogo from "assets/ky/cima-logo.png";
import FeedIcon from "@mui/icons-material/Feed";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BackgroundImage from "assets/hero-image-background.jpeg";

const cardsContent = [
  {
    header: "Deep two-way liquidity",
    body: "RKFX is able to provide superior execution value and limit market risk and impact typically incurred when executing larger value trades",
    icon: (
      <SwapHorizIcon
        color="primary"
        sx={{
          height: 56,
          width: 56,
        }}
      />
    ),
  },
  {
    header: "Enhanced market perspective",
    body: "Clients of RKFX are supported by an experienced team with in-depth industry knowledge and expertise.",
    icon: (
      <ShowChartIcon
        color="primary"
        sx={{
          height: 56,
          width: 56,
        }}
      />
    ),
  },
  {
    header: "Funding and settlement made simple",
    body: "Same day funding and account settlement in G10 currencies at local settlement times.",
    icon: (
      <AccountBalanceIcon
        color="primary"
        sx={{
          height: 56,
          width: 56,
        }}
      />
    ),
  },
  {
    header: "Market intelligence",
    body: "By giving our clients a macro and micro view of markets, we aim to keep you informed and up to date with the information that matters to you.",
    icon: (
      <FeedIcon
        color="primary"
        sx={{
          height: 56,
          width: 56,
        }}
      />
    ),
  },
];

export default function HomePageKY() {
  const navigate = useNavigate();
  return (
    <>
      {/* Hero unit */}

      <Container
        disableGutters={true}
        maxWidth={false}
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          display: "flex",
          alignItems: " center",
          minHeight: "100vh",
        }}
      >
        <Grid container direction="column">
          <Grid item xs={12}>
            <Typography
              variant="h3"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Premium currency trading has arrived
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              paragraph
            >
              A personalized concierge service to help you securely trade
              currencies.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              {/* this links to the contact form */}
              <Button
                variant="contained"
                onClick={() => navigate("/contact-us")}
              >
                Learn more
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>

      <Container
        maxWidth={false}
        style={{
          display: "flex",
          alignItems: " center",
          minHeight: "100vh",
          marginTop: 40,
        }}
      >
        <Grid container direction="column">
          <Grid item xs={12}>
            <Typography
              component="h1"
              variant="h4"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Personal account coverage tailored to you
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent={"center"}
            alignItems={"center"}
            display="flex"
          >
            <Typography
              variant="h6"
              align="center"
              color="text.secondary"
              paragraph
              style={{ maxWidth: 800 }}
            >
              We offer a personalized and seamless experience for 
              currency conversions. As a boutique high touch firm,
              <strong>
                {" "}
                we require a minimum notional value of $50,000 per trade.
              </strong>
            </Typography>
          </Grid>
          <Box m={3} />
          <Grid item container xs={12} spacing={4}>
            {cardsContent.map((card, index) => (
              <Grid item key={index} xs={12} sm={6} md={6}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {card.icon}
                  </CardContent>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="h2"
                      textAlign="center"
                    >
                      {card.header}
                    </Typography>
                    <Typography textAlign="center">{card.body}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Container>
      <Container
        maxWidth={false}
        style={{
          display: "flex",
          alignItems: " center",
          minHeight: "100vh",
          marginTop: 40,
          marginBottom: 40,
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h4" align="center" color="text.primary">
              Our Partners
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <PartnerCard logo={ProvenanceLogo} altText="aml-logo" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <PartnerCard logo={DentonsLogo} altText="dentons-logo" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <PartnerCard logo={RSMLogo} altText="rsm-logo" />
          </Grid>
          {/* <Grid item xs={12} sm={3}>
            <PartnerCard logo={CIMALogo} altText="cima-logo" />
          </Grid> */}

          <Grid
            item
            xs={12}
            container
            display="flex"
            justifyContent={"center"}
            m={3}
          >
            <Divider style={{ borderStyle: "dotted", width: "50%" }} />
          </Grid>
          <Grid item xs={12} container>
            <Grid item xs={12} sm={6} container>
              <Grid item xs={12}>
                <Typography variant="h4" color="text.primary" gutterBottom>
                  A white glove service to securely and quicky trade currencies.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" color="text.primary">
                  RKFX is a gateway offering access to the worlds
                  best liquidity. RKFX has access to extensive market resources
                  that are fine tuned to your specific needs. By taking a
                  strategic approach, RKFX will craft a solution to suit your
                  specific goals.
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* this links to the contact form */}
              <Button
                style={{ marginTop: 24 }}
                variant="contained"
                onClick={() => navigate("/contact-us")}
              >
                Let&apos;s Work Together
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
