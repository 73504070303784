import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavigationBar from "components/NavigationBar/NavigationBar";
import KYFooter from "components/Footer/KYFooter";
import { Navigate } from "react-router-dom";
import HomePageKY from "pages/KY/HomePageKY/HomePageKY";
import TermsAndConditionsKY from "pages/KY/TermsAndConditionsKY/TermsAndConditionsKY";
import ContactUSPage from "pages/generic/ContactUS/ContactUSPage";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <NavigationBar />
      <Routes>
      <Route path="/" element={<HomePageKY />} />
      <Route path="/terms-conditions" element={<TermsAndConditionsKY />} />
      <Route path="/contact-us" element={<ContactUSPage />} />
      <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <KYFooter/>
    </BrowserRouter>
  );
};
export default AppRoutes;
