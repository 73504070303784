import React from "react";
import { Container, Grid, Typography, Box } from "@mui/material";


// this needs to be updated
const TermsAndConditionsKY = () => {
  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        pt: 8,
        pb: 6,
      }}
    >
      <Container maxWidth="md">
        <Grid container direction="column">
          <Grid item xs={12}>
            <Typography variant="h3" color="text.primary" align="left">
              Terms And Conditions
            </Typography>
          </Grid>
          <br />
          <Grid item xs={12}>
            <Typography variant="h6" color="text.primary" align="left">
              Last Update: January 9, 2023
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <br />
            <Typography color="text.primary" align="left">
              PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY. Your use
              of the services offered by RKFX (and the content provided by
              RKFX on or through the services (collectively, the
              “Platform”) are expressly conditioned on your acceptance without
              modification of the following terms and conditions (the “Terms and
              Conditions”). If you accept these Terms and Conditions, click on
              the “I Accept” button below. That action is the equivalent of your
              signature and indicates your acceptance of these Terms and
              Conditions and that you intend to be legally bound by them. RKFX
              Ltd. (“RKFX”) may modify, supplement or amend these Terms and
              Conditions at its sole discretion. If so, you will be notified the
              next time you use the Platform, and given an opportunity to accept
              the modifications. Your continued use of the Platform will be
              evidence that you acknowledge and agree to be bound by the
              modified Terms and Conditions. If you do not accept the
              modifications, you are no longer entitled to use the Platform.
            </Typography>
            <br />

            <Typography variant="h6" color="text.primary" align="left">
              A. Use of the Platform
            </Typography>
            <br />
            <Typography color="text.primary" align="left" component="ol">
              <li>
                RKFX grants you a limited, non-exclusive, non-transferable and
                personal license to access and use the Platform, provided you
                have created an Account (as defined below) and are in compliance
                with these Terms and Conditions.
              </li>
              <li>
                All content included on the Platform is the property of RKFX or
                its content suppliers and protected by international copyright
                laws. You agree not to permit access to the Platform to others,
                and not to publish, reproduce, sublicense or modify any content
                appearing on the Platform for any purpose without the prior
                written consent of RKFX.
              </li>
              <li>
                You may not use the Platform if you live in a jurisdiction where
                access to or use of the Platform or any part of it may be
                illegal or prohibited. It is solely your responsibility to
                determine whether your use of the Platform is lawful in the
                jurisdiction in which you reside, and you agree to comply with
                all applicable laws in your use of the Platform.
              </li>
              <li>
                You shall be responsible for all access and service fees
                necessary to connect to or communicate with the Platform and
                assume all charges incurred in accessing such systems.
              </li>
              <li>
                You will implement and maintain appropriate protection in
                relation to the security and control of access to your computer,
                computer viruses or other similar harmful or inappropriate
                materials, devices, information or data. You assume all risks
                associated with the use and storage of information on your
                personal computer or on any other computer through which you
                will gain access to the Platform.
              </li>
              <li>
                In its sole discretion and for any reason, in addition to any
                other rights or remedies available to RKFX and without any
                liability whatsoever, RKFX at any time and without notice may
                terminate or restrict your access to any component of the
                Platform.
              </li>
              <br />
              <Typography variant="h6" color="text.primary" align="left">
                B. Platform Account
              </Typography>
              <br />
              <ol start={7}>
                <li>
                  You must create an account in order to access the Platform
                  (your “ Account ”). When you create an Account, you will be
                  required to provide personal information and about your use of
                  the Platform to RKFX. RKFX may, in its discretion, accept or
                  reject your application for an Account.
                </li>
                <li>
                  All use, collection, and disclosure of your personal
                  information will be governed by RKFX’s Privacy Policy, which
                  is available at RKFX.io/ky/privacy. You have read RKFX’s
                  Privacy Policy, and you agree that the terms of that policy
                  are reasonable. You consent to the collection, use and
                  disclosure of your personal information by RKFX in accordance
                  with the terms of and for the purposes set out in RKFX’s
                  Privacy Policy.
                </li>
                <li>
                  You agree that RKFX, or a service provider engaged by RKFX,
                  may verify your name, address, phone number, and other Account
                  details against information about you held by third party
                  sources in order to verify your identity to fulfill its
                  anti-money laundering obligations and to help protect against
                  fraud or misappropriation of user Accounts.
                </li>
                <li>
                  You covenant and represent that you will provide truthful,
                  accurate and complete information upon opening your Account.
                  If any of the informationprovided by you to RKFX changes, you
                  agree to provide RKFX with sufficient details no later than 30
                  days after such change.
                </li>
                <li>
                  Your Account may be accessed only by use of your account name
                  and password, or if you are accessing the platform in person
                  or by telephone, by other identifying information agreed upon
                  between you and RKFX from time to time (your “Credentials”).
                  You are solely responsible and liable for any use and misuse
                  of your Credentials and for all activities that occur under
                  your Credentials. For security reasons, you must keep your
                  Credentials confidential and not disclose them to any person
                  or permit any other person to use them, except with the prior
                  written consent of RKFX.
                </li>
                <li>
                  You must immediately notify RKFX of any unauthorized use of
                  your Credentials, or if you know or suspect that your
                  Credentials have been lost or stolen, has become known to any
                  other person, or has been otherwise compromised. You agree to
                  take full responsibility for all use and misuse that occurs
                  under your Account (including all transactions using your
                  Account) and for all resulting loss, damage, and liability.
                </li>
                <li>
                  If you allow any third party to access the Platform (including
                  any of your Accounts) in a manner unauthorized by RKFX
                  (including, without limitation, screen scrapping), you will
                  indemnify RKFX and its employees, officers, directors, and
                  agents and hold each of them harmless against any and all
                  losses, liability, costs or damages arising out of any claim
                  or suit by any such third party based upon or relating in any
                  way to such access or use of the Platform.
                </li>
              </ol>
              <br />
              <Typography variant="h6" color="text.primary" align="left">
                C. Trading
              </Typography>
              <br />
              <ol start={14}>
                <li>
                  In order to initiate a trade using the Platform, you must
                  first either: transfer United States Dollars (collectively,
                  “Fiat Currency”), to a bank account controlled by RKFX; or
                  transfer bitcoin or ether (collectively, “Cryptocurrency”) to
                  a digital wallet controlled by RKFX.
                </li>
                <li>
                  Fiat Currency or Cryptocurrency transferred by you to RKFX
                  (hereinafter referred to as “Customer Funds”) in accordance
                  with section 14 will be credited to yourAccount and held by
                  RKFX on your behalf separately from its operating bank
                  account.
                </li>
                <li>
                  There will be no interest paid on any Customer Funds held by
                  RKFX for your Account.
                </li>
                <li>
                  Upon receiving written or verbal instructions from you, RKFX
                  may faciliate a trade of your Customer Funds for another Fiat
                  Currency or Cryptocurrency (a “ Trade ”). All instructions
                  provided by you to RKFX are considered final and all Trades
                  are non-refundable and executed on a reasonable efforts basis.
                  RKFX is not responsible for any incorrect instructions
                  provided by you.
                </li>
                <li>
                  Any proceeds from a Trade by you will be credited to your
                  Account, less any fees due to RKFX for the Trade and any other
                  services RKFX may provide.
                </li>
                <li>
                  Proceeds from each trade will be immediately transferred to
                  your bank account via wire transfer or to your digital asset
                  wallet on the blockchain. Transfers will occur as soon as
                  commercially viable and not extending a period of 5 business
                  days.
                </li>
                <li>
                  RKFX’s prices are all in. Prices take into account all
                  expenses incurred by RKFX and are considered binding for both
                  parties.
                </li>
              </ol>
              <br />
              <Typography variant="h6" color="text.primary" align="left">
                D. Risks and Prohibited Conduct
              </Typography>
              <br />
              <ol start={21}>
                <li>
                  You acknowledge that RKFX does not guarantee the accuracy of
                  exchange rates and does not guarantee that the Trades
                  facilitated by RKFX will be favourable or be based on accurate
                  information. You are solely responsible for any trading
                  decision made and accept any loss or foregone financial
                  benefit, even if the decision is based on information provided
                  by RKFX.
                </li>
                <li>
                  RKFX does not provide any trading advice or recommendations.
                  No orders are reviewed by RKFX for suitability.
                </li>
                <li>
                  You are responsible for complying with applicable laws in your
                  use of the Platform. Do not use the Platform if buying and
                  selling Cryptocurrency is not legal in your jurisdiction.{" "}
                </li>
                <li>
                  You agree that RKFX may, in its discretion, determine not to
                  hold digital assets that result from hard forks of
                  Cryptocurrencies. In this circumstance, if you wish to acquire
                  assets resulting from a hard fork, you should withdraw your
                  Customer Funds prior to the hard fork.
                </li>
                <li>
                  You acknowledge that Cryptocurrencies involve significant
                  risk. These risks include, but are not limited to:
                  <ul>
                    <li>a. Cryptocurrency prices are volatile;</li>
                    <li>b. digital wallets and exchanges may be hacked;</li>
                    <li>
                      c. holders of Cryptocurrencies do not have the same legal
                      rights associated with security ownership or deposits with
                      regulated financial institutions;
                    </li>
                    <li>
                      d. it may be difficult to find accurate and current
                      information relating to Cryptocurrencies;
                    </li>
                    <li>e. Trades may take several days to settle; and</li>
                    <li>
                      f. amounts of Fiat Currency and Cryptocurrency held by
                      RKFX on your behalf are not protected by any deposit
                      insurance or investment protection scheme. You acknowledge
                      that in no event will RKFX be responsible for any losses
                      or damage to you as a result of these risks.
                    </li>
                  </ul>
                </li>
              </ol>
              <br />
              <Typography variant="h6" color="text.primary" align="left">
                E. Representations and Warranties
              </Typography>
              <br />
              <ol start={26}>
                <li>
                  You represent and warrant that:
                  <ul>
                    <li>
                      a. If you are an individual, that you are of the age of
                      majority, that you have the capacity to enter into and be
                      bound by these Terms and Conditions, and that you will use
                      the Platform under your own name and for your sole
                      benefit;
                    </li>
                    <li>
                      b. If you are a legal entity other than an individual, the
                      legal entity is duly organized and existing and the
                      individual accepting these Terms and Conditions is of the
                      age of majority and has the authority to enter into and
                      cause the legal entity to be bound by these Terms and
                      Conditions;
                    </li>
                    <li>
                      c. The information provided by you upon to RKFX upon
                      opening your Account is accurate and complete;
                    </li>
                    <li>
                      d. You are not prohibited or restricted from using the
                      Platform.
                    </li>
                  </ul>
                </li>
              </ol>
              <br />
              <Typography variant="h6" color="text.primary" align="left">
                F. Liability disclaimer – READ THE FOLLOWING CAREFULLY
              </Typography>
              <br />
              <ol start={27}>
                <li>
                  The Platform is provided on an “as is” and “as available”
                  basis. To the fullest extent permitted by law, RKFX expressly
                  disclaims all representations and warranties, whether express
                  or implied, statutory or otherwise, including:
                  <ul>
                    <li>(1) any warranty of merchantability;</li>
                    <li>(2) fitness for a particular purpose;</li>
                    <li>(3) non-infringement;</li>
                    <li>
                      (4) that the Platform or any Cryptocurrency acquired using
                      the Platform will meet your requirements; and
                    </li>
                    <li>
                      (5) that your access to the Platform will be uninterrupted
                      or error-free.
                    </li>
                  </ul>
                </li>
                <li>
                  In no event will RKFX or any of its directors, officers,
                  employees, or agents be liable for any injury, loss, claim,
                  damage, or any special, exemplary, punitive, direct, indirect,
                  incidental or consequential damages of any kind (including,
                  but not limited to economic loss, foregone financial benefit
                  or lost savings), whether based in contract, tort, strict
                  liability, or otherwise, which arises out of or is in any way
                  connected with any use of the Platform or its content, or the
                  services offered on the Platform, even if advised of the
                  possibility of damages.
                </li>
                <li>
                  If RKFX should be found liable for any loss or damage which
                  arises out of or is in any way connected with any of the
                  functions or uses of the Platform or its content, or any of
                  the services offered on the Platform, the liability of RKFX
                  will in no event exceed in the aggregate the amount paid by
                  you under these Terms and Conditions for the specific services
                  to which the loss or damage relates.
                </li>
                <li>
                  You are solely responsible for any and all tax consequences
                  you may incur as a result of your use of the Platform.
                </li>
              </ol>
              <br />
              <Typography variant="h6" color="text.primary" align="left">
                G. Indemnification
              </Typography>
              <br />
              <ol start={31}>
                <li>
                  You agree to indemnify and save harmless RKFX, its affiliates
                  and any of their respective officers, directors, employees,
                  consultants, representatives and agents from and against any
                  claim, cause of action or demand, including without limitation
                  reasonable legal, accounting and other professional fees,
                  brought as a result of your use of the Platform or your breach
                  of these Terms and Conditions.
                </li>
              </ol>
              <br />

              <Typography variant="h6" color="text.primary" align="left">
                H. Survival
              </Typography>
              <br />
              <ol start={32}>
                <li>
                  Sections F, G and I will survive the termination of your
                  access to the Platform.
                </li>
              </ol>
              <br />
              <Typography variant="h6" color="text.primary" align="left">
                I. General
              </Typography>
              <br />
              <ol start={33}>
                <li>
                  These Terms and Conditions may not be assigned by you or
                  otherwise transferred in whole or in part without the prior
                  written consent of RKFX. RKFX may assign these Terms and
                  Conditions without obtaining your consent.
                </li>
                <li>
                  The relationship between RKFX and you will be that of
                  independent contractors, and neither party nor any of its
                  respective officers, agents or employees will be held or
                  construed to be partners, joint ventures, fiduciaries,
                  employees or agents of the other as a result of these Terms
                  and Conditions or your use of the Platform.
                </li>
                <li>
                  You consent and submit to the exclusive jurisdiction of the
                  courts located in the Cayman Islands, in all disputes arising
                  out of or relating to the use of the Platform and these Terms
                  and Conditions. This agreement and its performance will be
                  governed by the laws of the Cayman Islands.
                </li>
                <li>
                  These Terms and Conditions will be severable. In the event
                  that any provision is determined to be unenforceable or
                  invalid, that provision will nonetheless be enforced to the
                  fullest extent permitted by applicable law, and that
                  determination will not affect the validity and enforceability
                  of any other remaining provisions of these Terms and
                  Conditions.
                </li>
                <li>
                  These Terms and Conditions, together with those incorporated
                  or referred to in these Terms and Conditions, constitute the
                  entire agreement between you and RKFX pertaining to the
                  subject matter of these Terms and Conditions, and supersede
                  any prior agreements, understandings, negotiations and
                  discussions, whether electronic, oral or written, regarding
                  the subject matter of these Terms and Conditions, and may not
                  be amended or modified except by the Company as set out above.
                  There are no representations, warranties or other agreements
                  between us in connection with the subject matter of these
                  Terms and Conditions, except as specifically set out in these
                  Terms and Conditions or in those incorporated or referred to
                  in these Terms and Conditions. No party has been induced to
                  enter into these Term and Conditions in reliance on, and there
                  will be no liability assessed, either in tort or contract,
                  with respect to, any warranty, representation, opinion, advice
                  or assertion of fact, except to the extent it has been reduced
                  to writing and included in these Terms and Conditions or in
                  those incorporated or referred to in these Terms and
                  Conditions.
                </li>
                <li>
                  You consent to receive communications from RKFX
                  electronically. You agree that all agreements, notices,
                  disclosures and other communications that RKFX provides to you
                  electronically satisfy any legal requirement that such
                  communications be in writing.
                </li>
                <li>
                  The legal business name of the Company is RKFX Please
                  direct enquiries to the Company’s registered business address
                  at DDM Corporate Ltd., One Capital Place, 3rd floor, 136
                  Shedden Road, PO Box 10190, George Town, Grand Cayman, Cayman
                  Islands or by telephone at (226) 240-7189 or e-mail at
                  admin@RKFX.io.
                </li>
              </ol>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default TermsAndConditionsKY;
